import React, { Component } from 'react';
export default class ContactUs extends Component {
  render() {
    let resumeData = this.props.resumeData;
    return (
      <section id="contact">
          <div className="row section-head">
            <div className="ten columns">
              <p className="lead">
              Feel free to contact me via one of the below links:
              </p>
            </div>
          </div>
          <div className="row">
            <aside className="eigth columns footer-widgets">
              <div className="widget">
                {/* <h4>Linkedin :  */}
                <h4>
                  {/* {resumeData.linkedinId} */}
                  <a href="https://linkedin.com/in/bhaskara-rudraraju" target="_blank" >Linkedin</a>    
                </h4>
                <h4> 
                <a href="https://myreadyset.com/quick-links/contact-us/" target="_blank" >Myreadyset</a>               
                </h4>
                <h4> 
                <a href="https://crcfbt.org/ContactUs.php" target="_blank" >CRCFBT</a>               
                </h4> 
                <h4> 
                <a href="https://crcfbt.org/ContactUs.php" target="_blank" >Text Me @ 614-446-1794</a>               
                </h4>                
              </div>
            </aside>
          </div>
        </section>
        );
  }
}