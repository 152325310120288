let resumeData = {
    "imagebaseurl":"https://bhaskara-rudraraju.com",
    "name": "Bhaskara Rudraraju",
    "role": "Full Stack Developer / DevOps Coach / BRMS/BPM Tech Consultant",
    "linkedinId":"https://www.linkedin.com/in/bhaskara-rudraraju",
    "skypeid": "rudrarb",
    "roleDescription": "Since 1998, I have been working at the Nationwide Insurance Company, Columbus, Ohio, USA. Before Nationwide, I worked ten years (1988-98) for Visakhapatnam Steel Plant and two years (1986-88) for Hindustan Aeronautics Ltd(HAL). My passion is to learn, practice, and share knowledge.",
    "socialLinks":[
        {
          "name":"linkedin",
          "url":"https://www.linkedin.com/in/bhaskara-rudraraju",
          "className":"fa fa-linkedin"
        },
        {
          "name":"github",
          "url":"https://github.com/rudrarb1",
          "className":"fa fa-github"
        },
        {
          "name":"skype",
          "url":"https://twitter.com/rudrarb",
          "className":"fa fa-twitter"
        }, 
        {
          "name":"facebook",
          "url":"https://facebook.com/bhaskara.rudraraju",
          "className":"fa fa-facebook"
        },
        {
          "name":"instagram",
          "url":"https://instagram.com/rudrarb",
          "className":"fa fa-instagram"
        }
      ],
    "aboutme":"I like dabbling in various new market trends, learning about new technologies, and sharing learning knowledge with others. I started my software career as a mainframe developer, and now I am working as a DevOps Coach / BRMS Tech Consultant.  I worked in multiple technology domains (Cloud, Web, Citrix, Desktop apps, Business Rules, Business Process). I gained strong knowledge in DevOps / CICD pipeline development, Cloud migrations, server administration architecture, consistent project delivery, coaching, and mentoring. I enjoy volunteering for different charities in my free time and conducts various educational service activities to help children across Central Ohio.",
    "address":"Columbus, Ohio, USA",
    "website":"https://bhaskara-rudraraju.com",
    // "resumedownload":"http://www.crcfbt.org/docs/Bhaskara-MM-Profile.pdf ",
    "education":[
      {
        "UniversityName":"Carnegie Mellon University(USA)",
        "specialization":"Master's Certificate in Software Architecture",
        "MonthOfPassing":"May",
        "YearOfPassing":"2006",
        "Achievements":"Learned: Software architecture is the primary carrier of system qualities, such as performance, modifiability, and security. Architecture helps ensure that a design approach will yield an acceptable system and holds the key to maintenance and sustainment efforts. In short, architecture is the glue that holds every phase of a project together. To realize these benefits, you need to build the capability to architect effectively. The SEI offers the Software Architecture Professional Certificate to equip software professionals with state-of-the-art practices for designing, analyzing, documenting, and implementing software architectures"
      },
      {
        "UniversityName":"The George Washington University(USA)",
        "specialization":"Master's Certificate in Project Management",
        "MonthOfPassing":"May",
        "YearOfPassing":"2003",
        "Achievements":"Learned: Risk Management, core project management competencies, including strategic project planning and scheduling, and time, cost and scope management. Practical project management tools including Gantt charts, project initiation documents (PID) and work breakdown structure (WBS). Key interpersonal, leadership and strategic skills to enhance team motivation and stakeholder engagement for effective project execution."
      },
      {
        "UniversityName":"Central Michigan University(USA)",
        "specialization":"Master of Science in Software Engineering Administration",
        "MonthOfPassing":"May",
        "YearOfPassing":"2001",
        "Achievements":"Learned: Foundations of Research Methods in Administration, Organizational Dynamics and Human Behavior, Financial Analysis, Planning and Control, Strategic Planning for the Administrator, Administration, Globalization and Multiculturalism, Directed Administrative Portfolio, and Applied Research Project in Administration, Production Concepts, Application of Industrial Management Principles, Technology and Environment, Project Management, Lean Manufacturing & Six Sigma Quality, and Sustainability and Green Technology."
      },
      {
        "UniversityName":"Jawaharlal Nehru Technological University (India)",
        "specialization":"Post-Graduation Diploma In Industrial Engineering",
        "MonthOfPassing":"May",
        "YearOfPassing":"1994",
        "Achievements":"National Institute of Ecology and Environment, Kakinada, AP, India"
      },
      {
        "UniversityName":"The Institution of Engineers (India)",
        "specialization":"Bachelor Of Science in Mechanical Engineering/AMIE",
        "MonthOfPassing":"May",
        "YearOfPassing":"1991",
        "Achievements":"Associate Member of The Institution of Engineers (India) (AMIE)."
      },
      {
        "UniversityName":"The Linux Foundation",
        "specialization":"Certified Kubernetes Application Developer (CKAD)",
        "MonthOfPassing":"August",
        "YearOfPassing":"2019",
        "Achievements":"https://www.cncf.io/certification/ckad/"
      },
      {
        "UniversityName":"AWS",
        "specialization":"Certified Solution Architect Associate (SAA)",
        "MonthOfPassing":"December",
        "YearOfPassing":"2019",
        "Achievements":"https://aws.amazon.com/certification/certified-solutions-architect-associate"
      },
      {
        "UniversityName":"The Linux Foundation",
        "specialization":"Certified Kubernetes Administrator (CKA)",
        "MonthOfPassing":"Jan",
        "YearOfPassing":"2021",
        "Achievements":"https://www.cncf.io/certification/cka/"
      },
      {
        "UniversityName":"Project Management Institute (PMI)",
        "specialization":"Project Management Prof. (PMP)",
        "MonthOfPassing":"December",
        "YearOfPassing":"2020",
        "Achievements":"https://www.pmi.org/certifications/project-management-pmp"
      },
      {
        "UniversityName":"IBM",
        "specialization":"Certified Application Developer - Operational Decision Manager Standard",
        "MonthOfPassing":"May",
        "YearOfPassing":"2013",
        "Achievements":"https://www.ibm.com/certify/cert?id=C0000900"
      }
    ],
    "work":[
      {
        "CompanyName":"Nationwide Insurance, Columbus, Ohio, USA",
        "specialization":"Full Stack Developer (Web/Distributed/Citrix/Desktop/Mainframe Platforms) / DevOps Coach / Cloud Migrations  / BRMS/BPM Tech Consultant",
        "MonthOfLeaving":"June 1998 - ",
        "YearOfLeaving":"Present",
        "Achievements":"1) ODM/BPM Consultant: Led the teams to design/develop/support 80K+ business rules for 13 business units and migrated apps to the IBM Cloud. 2) Cloud Migrations: Embedded Tech Consultant to various business units.  Helped/trained teams to develop 35+ CI/CD pipelines. 3) App Dev: Multiple domains knowledge, full-stack development (SpringBoot, Microservices, APIs, ASP, Java, Angular, Python, Mainframe apps, VB, COBOL), and proven strong hands-on experience. 4)Architecture: Managed apps with high-performance, high-availability, reliability, security, concurrency, data flow design, and successful integration with interfaces. 5) Tier-1 Programs: Worked with teams on many tier-1 projects by aligning business goals with technology solutions to drive process improvements. 7) Decision Velocity: Completed BRMS Decision Velocity. 8) Mentoring Developers and Teaching Thursdays Instructor since 2013."
      },
      {
        "CompanyName":"Visakhapatnam Steel Plant (India)",
        "specialization":"Chargeman/Developer",
        "MonthOfLeaving":"Nov 1988 -",
        "YearOfLeaving":"June 1998",
        "Achievements":"Raw Material Handling System. Received 63 Quality Circle / Suggestion Scheme Awards"
      },
      {
        "CompanyName":"Hindustan Aeronautical Ltd (India)",
        "specialization":"CAD Designer",
        "MonthOfLeaving":"1986 -",
        "YearOfLeaving":"1988",
        "Achievements":""
      }
    ],
    "skillsDescription":"Full Stack Development",
    "skills":[
      {
        "skillname":"Cloud: AWS, IBM, Google, Azure "
      },
      {
        "skillname":"CI/CD Tools: Docker, Kubernetes, Helm, Art, Nexus Concourse, Jenkins, Harness, Liquibase.."
      },
      {
        "skillname":"Front-end Dev: Java, SpringBoot, Angular, Python, JavaScript, PHP,.Net ,HTML5, APIs,APIgee."
      },
      {
        "skillname":"Database: DB2, UDB, SQLServer, Oracle, Redis DynamoDB, MongoDB, MySQL, and Informatica.."
      },
      {
        "skillname":"Platforms: Microsoft IIS, WebSpehere, Citrix, Mainframe"
      },
      {
        "skillname":"Business Rules/ Business Process: IBM ODM, BPM, Drools, OpenRules, PegaRules, Camunda BPM"
      },
      {
        "skillname":"ML/AI: ODM , OpenRules, and AWS DeepRacer.."
      }
    ],
    "portfolio":[      
      {
        "name":"Educational Seminars",
        "description":"Conducts educational information sessions in Columbus, Ohio 2 times/year to share educational resources",
        "imgurl":"images/portfolio/educational-seminars.jpg",
        "url":"https://myreadyset.com/resources/free-educational-seminar/"
      },
      {
        "name":"Knowledge Sharing Website",
        "description":"Developed a Knowledge Sharing Website to help children education needs",
        "imgurl":"images/portfolio/myreadyset-knowledge-sharing.JPG",
        "url":"https://myreadyset.com/"
      },      
      {
        "name":"Business Rules Development-Accelerators",
        "description":"Articles/Accelarators that I added at Nationwide to uplift BRMS developers skills",
        "imgurl":"images/portfolio/BRMS.jpg",
        "url":"https://www.nationwide.com"
      },  
      {
        "name":"Learn and Practice DevOps",
        "description":"GitRepo I built to share DevOps knowledge across Nationwide",
        "imgurl":"images/portfolio/DevOps.jpg",
        "url":"https://www.nationwide.com"
      },
      {
        "name":"Founded non-profit in 2009: Community Recycling Campaign",
        "description":"Developed and managed this non-profit Website",
        "imgurl":"images/portfolio/crc-nonprofit.JPG",
        "url":"https://www.crcfbt.org/"
      },
      {
        "name":"North South Foundation",
        "description":"Led the Columbus Chapter Coordinator from 2002-2013, as a convener conducted 2007 NSF OSU Finals, and founded parent-run prep-clubs for students in Columbus, Ohio",
        "imgurl":"images/portfolio/nsf-nonprofit.JPG",
        "url":"https://www.northsouth.org/public"
      },
      {
        "name":"CRC Invitational Science Fair",
        "description":"I started the CRC invitational science fair in 2010 to help Ohio students to enter District/State/Intel International Science Fairs",
        "imgurl":"images/portfolio/crc-science-fair.jpg",
        "url":"https://www.crcfbt.org/Science_Fair.php"
      },
      {
        "name":"Advocacy at The Capitol Hill",
        "description":"Once a year I participate in an advocacy activity at The Capitol Hill, Washington DC",
        "imgurl":"images/portfolio/advocacy-at-thecapitolhill.JPG",
        "url":"https://www.visitthecapitol.gov/"
      },
      {
        "name":"Advocacy at The Ohio State House",
        "description":"Once a year I participate in an advocacy activity at The Ohio State House, Columbus, Ohio.",
        "imgurl":"images/portfolio/advocacy-at-the-ohio-state-house.JPG",
        "url":"http://www.ohiostatehouse.org/"
      },
      {
        "name":"Mentoring-CRC Ambassador Program",
        "description":"Started Ambassador program in 2015- goal is to foster learning and civic engagement through community service, volunteerism and leadership in highly motivated middle and high school students.",
        "imgurl":"images/portfolio/ambassador-program.jpg",
        "url":"https://www.crcfbt.org/Ambassador_Program.php"
      },
      {
        "name":"CRC ADOPT-A-SCHOOL program",
        "description":"Founded Adopt-A-School program in 2009- goal is to raise recycling awareness and to run some small recycling projects in high schools and to support specially challenged schools in India.",
        "imgurl":"images/portfolio/adopt-a-school.JPG",
        "url":"https://www.crcfbt.org/Adopt-A-School.php"
      }     
    ],
    "testimonials":[
      {
        "description":"Participated as a member in the Nationwide Enterprise CIOs Associate Advisory Group and managed AAG monthly newsletter.",
        "name":"@ Nationwide(USA)"
      },
      {
        "description":"Leadership: Nationwide's first -Leaders Of Tomorrow- program participant, Advocay at The Capitol Hill and The Ohio State House, CRC Invitational Science Fair coordinator for Ohio State Schools, 2007 NSF OSU Finals Convener, 2002-2013 NSF Columbus Chapter Coordinator, CRC Ambassador Program Coordinator, Founder of NSF Columbus Chapter Parent Run Pre-Clubs, and more..",
        "name":"@ at work and outside of my work (USA)"
      },
      {
        "description":"Nationwide Recognitions: Received many Bravos, I got caught awards, and SOAR Awards. 2010 Nationwide Volunteer Of The Year Finalist. Received the Nationwide -Commercial WOW- Award and many cash prizes for Cost Savings projects",
        "name":"@ Nationwide (USA)"
      },
      {
        "description":"Community Service: Received -Outstanding Community Service Awards- from Federation of Indian Associations(FIA), North South Foundation (NSF), The US Consulate General/Ambassador, and Invitation to The White House for the PEYA Award...and more",
        "name":"@ community service awards (USA)"
      },
      {
        "description":"Quality Circles: Received 60+ quality circle awards, suggestion scheme awards, and cash prizes",
        "name":"@ Visakhapatnam Steel Plant (India)"
      }

    ]
  }
  
  export default resumeData